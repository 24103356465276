.addProductPage {
  .addForm {
    max-width: 600px;

    select,
    option {
      text-transform: capitalize;
    }

    & > div {
      margin-bottom: 16px;
    }
  }
  .product-toast {
    left: 20px !important;
    bottom: 80px !important;
    color: white;
  }

  .add-product-btn {
    background-color: var(--secondary);
    border: 0;
  }
}
