@font-face {
  font-display: swap;
  font-family: "Lexend Deca";
  font-weight: 300;
  src: url("LexendDeca-Light.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Lexend Deca";
  font-weight: 400;
  src: url("LexendDeca-Regular.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Lexend Deca";
  font-weight: 500;
  src: url("LexendDeca-Medium.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Lexend Deca";
  font-weight: 600;
  src: url("LexendDeca-SemiBold.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Queens Medium";
  font-weight: 500;
  src: url("Queens-Medium.woff2") format("woff2");
}
