.loginPage {
  .login-header {
    width: 150px;
  }

  .border-brand {
    width: calc(100% - 24px);
    z-index: 1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-color: #00add8 !important;
  }

  .login-submit {
    background-color: #00add8;
    border: none;

    &:hover {
      opacity: 0.8;
    }
  }
}
