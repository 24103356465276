.pageLoading {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes bounce-5082199c-00aa-4bab-80bc-0d1a749e38a2 {
  0% {
    animation-timing-function: cubic-bezier(0.1361, 0.2514, 0.2175, 0.8786);
    transform: translate(0, 0px) scaleY(1);
  }
  37% {
    animation-timing-function: cubic-bezier(0.7674, 0.1844, 0.8382, 0.7157);
    transform: translate(0, -39.96px) scaleY(1);
  }
  72% {
    animation-timing-function: cubic-bezier(0.1118, 0.2149, 0.2172, 0.941);
    transform: translate(0, 0px) scaleY(1);
  }
  87% {
    animation-timing-function: cubic-bezier(0.7494, 0.2259, 0.8209, 0.6963);
    transform: translate(0, 19.900000000000002px) scaleY(0.602);
  }
  100% {
    transform: translate(0, 0px) scaleY(1);
  }
}
