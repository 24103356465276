:root {
  --primary: #7fbc4e;
  --secondary: #00add8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  outline: none;
}

a {
  text-decoration: none !important;
}

body {
  font-family: "Lexend Deca";
  background: #f2f2f2 !important;
  font-size: 16px;
}

button {
  &:active {
    background-color: #00add8 !important;
    border-color: #00add8 !important;
  }
}

.app {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #7fbc4e;
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #7fbc4e;
    border-radius: 8px;
  }
}
