.contactUsPage {
  .topBanner {
    height: 275px;
  }
  .contactImg {
    background-image: url("../../images/contact/contact.webp");
    background-position: -47% 65%;
    background-size: 124%;
    height: 100%;
    position: absolute;
    right: 0;
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .leftSection {
    background-color: #bfdda6;
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
      padding: 16px 8px;
      svg {
        display: none;
      }
    }

    svg {
      height: 100%;
      right: 0;
      position: absolute;
      transform: translateX(52%);
      width: auto;
      fill: #bfdda6;

      path:last-child {
        fill: #79d0e5;
      }
    }

    .text {
      color: #343a40;
      transform: translateX(80px);

      h1 {
        font-weight: 700;
        font-size: 50px;
      }
      p {
        font-size: 18px;
      }

      @media (max-width: 768px) {
        transform: translateX(0);
        h1 {
          font-size: 24px;
        }
        p {
          font-size: 12px;
          width: 100% !important;
        }
      }
    }
  }

  .contactCard {
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    color: #343a40;

    svg {
      width: 50px;
      height: 50px;
      color: #00add8;

      path {
        stroke: #00add8;
      }
    }

    h2 {
      margin-top: 16px;
      font-weight: 600;
    }
  }

  .contactMap {
    height: 500px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .contactMap,
  .contactForm {
    border: 2px solid #00add8;
    border-radius: 8px;
  }
}

.contactForm {
  background-color: rgba(#00add8, 0.2);

  button {
    background-color: #7fbc4e;
    border: 0;
    width: 150px;

    &:hover {
      background-color: rgba(#7fbc4e, 0.5);
    }
  }

  .sent-message {
    font-size: 16px;
  }
}
