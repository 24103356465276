.productPage {
  margin-bottom: 60px;

  .product-section-1 {
    width: 65%;
  }

  .product-section-2 {
    width: 35%;
  }
  .product-section-1,
  .product-section-2 {
    text-align: center;

    .product-img {
      box-shadow: 0px 2px 15px rgba(#000, 0.1);
      cursor: pointer;
    }
  }

  .imagesContainer {
    min-height: 250px;

    @media (max-width: 768px) {
      min-height: 100px;
    }
  }

  .product-info {
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    @media (max-width: 768px) {
      font-size: 10px;
      img {
        width: 25px;
      }
    }

    img {
      width: 50px;
    }

    &:hover {
      background-color: rgba(#00add8, 0.2);
    }

    &-wrapper {
      box-shadow: 0px 2px 15px rgba(#000, 0.1);

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
    }
  }
}

.pswp__img {
  object-fit: contain;
}
