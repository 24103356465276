.categoryPage {
  .categoryProducts {
    text-align: center;
    .name {
      text-transform: capitalize;
    }

    .id {
      text-transform: uppercase;
      color: gray;
    }
  }

  .categoryProduct {
    width: 300px;
    min-height: 200px;
    cursor: pointer;
    box-shadow: 0px 2px 15px rgba(#000, 0.1);
    transition: background-color 0.4s ease-out, transform 0.4s;

    @media (max-width: 768px) {
      width: 100%;
    }

    &:hover {
      background-color: rgba(#7fbc4e, 0.3);
      transform: translateY(-8px);
    }

    img {
      min-height: 200px;
      transition: transform 0.2s;
    }
  }
  a {
    color: inherit;
  }

  .d-rtl {
    direction: rtl;
  }
}
