.homePage {
  color: #5c6c86;
  .videoWrapper {
    height: 600px;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 auto;
    width: 60%;

    @media (max-width: 768px) {
      height: 180px;
    }
  }
  .video {
    width: 100%;
  }

  .carousel {
    .carousel-inner {
      border-radius: 4px;
    }
  }

  .carouselImage {
    min-height: 400px;

    @media (min-width: 768px) {
      min-height: 600px;
      height: calc(100vh - 92.5px);
      max-height: calc(100vh - 92.5px);
    }
  }

  .carousel-caption {
    right: 30%;
    left: 30%;
    background-color: #fff;
    border-radius: 8px;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 1px;
    @media (max-width: 768px) {
      right: 15%;
      left: 15%;
      font-size: 16px;
    }
    .fixedTitle {
      color: #00add8;
    }
  }

  .banner {
    letter-spacing: 1px;
    margin-block: 180px;

    @media (max-width: 768px) {
      margin-block: 80px;
      padding: 0;
      row-gap: 30px;
      .textContent {
        padding: 0 !important;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }

    img {
      width: 600px;
    }
  }

  .banner1 {
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
    svg {
      margin-right: 8px;
      color: #00add8;
    }
    .textContent {
      padding-right: 90px;
    }
  }

  .banner2 {
    @media (max-width: 768px) {
      flex-direction: column;
    }

    h1 {
      letter-spacing: normal;
      font-size: 45px;
    }
    .textContent {
      padding-left: 90px;
    }
  }

  .banner3 {
    margin-inline: -24px;
  }

  .rightDirection {
    direction: rtl;
  }

  .homeCat {
    background-color: rgba(#7fbc4e, 0.3);
    margin-inline: -24px;

    & > div {
      padding: 70px 16px;

      @media (max-width: 768px) {
        padding: 24px;
      }
    }
  }

  .bg-img {
    position: absolute;
    left: -4%;
    top: -1%;
    right: 0%;
    bottom: 0%;
    z-index: -1;
    background-image: linear-gradient(
      180deg,
      rgba(#7fbc4e, 0.5),
      rgba(241, 242, 248, 0)
    );
    transform: rotate(-15deg);

    @media (max-width: 768px) {
      display: none;
    }
  }
}
