.categoriesSection {
  .catItem {
    display: block;
    min-height: 250px;
    border-radius: 8px;
    width: calc((100% - 48px) / 3);
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    color: #fafafd;

    @media (max-width: 768px) {
      width: 100%;
    }

    .text {
      font-size: 40px;
      line-height: 50px;
      text-align: center;
      text-transform: capitalize;
      font-weight: 800;
      position: relative;

      .viewWrapper {
        background-color: rgba(#00add8, 0.8);
        border-radius: 12px;
        padding: 8px 12px;
      }

      span,
      .viewWrapper {
        display: inline-block;
        transition: 0.3s cubic-bezier(0.5, -1, 0.5, 2);
        opacity: 0;
        transform: translate(0, -20px);
        font-size: 16px;
        line-height: normal;
      }

      &:before {
        content: attr(data-text);
        transition: 0.3s cubic-bezier(0.5, -1, 0.5, 2);
        letter-spacing: 2.5px;
        opacity: 1;
        transform: translate(0, 0px);
        position: absolute;
        width: 100%;
        left: 0;
      }

      &:hover,
      &:focus {
        &:before {
          opacity: 0;
          transform: translate(0, 20px);
        }
        .viewWrapper,
        span {
          opacity: 1;
          transform: translate(0, 0);
        }

        @for $i from 1 through 14 {
          span:nth-child(#{$i}) {
            transition-delay: 0.025s * $i;
          }
        }
      }
    }
  }
}
