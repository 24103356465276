.certificatesPage {
  padding-bottom: 120px !important;

  a {
    display: block;
    color: #00add8;
    padding: 4px 8px;
    border-radius: 8px;
    bottom: -40px;

    &:hover {
      background-color: rgba(#00add8, 0.2);
    }

    @media (max-width: 768px) {
      left: calc(50% - 110px);
    }
  }
}
