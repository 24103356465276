.ourProjectsPage {
  .img-wrapper {
    display: block;
    height: 324px;
    overflow: hidden;
    border-radius: 8px;
    width: calc((100% - 48px) / 3);
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    color: #fafafd;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
