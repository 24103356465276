.aboutUsPage {
  .files {
    flex-wrap: wrap;
    gap: 10px;

    > div {
      flex: 0 0 48%;
      height: 500px;
    }
  }
}
