//: primary #7FBC4E
// blue: #00ADD8
$primary: #7fbc4e;
$secondary: #00add8;

// home text color: #5c6c86

// app color: #343a40
.app {
  &.sticky {
    .header {
      animation: fadeIn 0.5s ease-out;
    }
  }
  .scrollTop {
    width: 55px;
    height: 55px;
    bottom: 100px;
    right: 30px;
    border-radius: 50%;
    z-index: 1300;
    color: #fff;
    background-color: $secondary;
    animation: pulse 2s infinite;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.show {
      opacity: 1;
    }

    @media (max-width: 768px) {
      bottom: 20px;
      right: 20px;
    }

    svg {
      margin-bottom: 4px;
      width: 24px;
      height: 24px;
    }
  }
}

.header {
  padding: 10px 40px;
  z-index: 1000;
  box-shadow: 0 2px 2px rgba($primary, 0.2);
  transition: all 0.5s ease;

  &-logo {
    transition: opacity 0.3s ease;
    img {
      width: 180px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .nav-products {
    text-transform: capitalize;
  }

  .dropdown-menu {
    padding-inline: 8px;
    margin-top: 0;

    .dropdown-item {
      border-radius: 4px;
      font-weight: 500;
      color: var(--bs-nav-link-color);
      &:hover {
        background-color: rgba($primary, 0.15);
        color: var(--bs-nav-link-hover-color);
      }
    }
  }

  .nav-link {
    font-weight: 500;
    border-radius: 4px;
    transition: background-color 0.15s ease;

    @media (min-width: 768px) {
      &:hover {
        background-color: rgba($primary, 0.15);
      }
    }

    &.active {
      color: $primary;
      font-weight: 700;
    }
  }

  .contactUs {
    background-color: #fff;
    color: $primary;
    border-color: $primary;
    padding-right: 24px;
    font-weight: 700;
    letter-spacing: 0.5px;
    border-bottom-left-radius: 1px !important;
    font-size: 14px;
    text-transform: uppercase;

    @media (min-width: 768px) {
      margin-left: 16px;
    }

    &-messageIcon {
      right: -27px;
      top: -6px;
      width: 44px;
      height: 44px;
      border-radius: 50px;
      background-color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 20px;
        color: #fff;
      }
    }

    &:hover {
      background-color: rgba($primary, 0.1);
    }
  }

  .searchProducts {
    width: 200px;
    position: relative;

    input,
    span {
      border-color: $primary;
    }

    input {
      box-shadow: none;
    }
    .search-wrapper {
      top: 39px;
      position: absolute;
      background-color: white;
      width: 100%;
      border-radius: 8px;
      padding: 8px;
      border: 1px solid $primary;
      max-height: 250px;
      overflow: auto;
    }

    .searchItem {
      cursor: pointer;
      border-radius: 4px;

      img {
        border-radius: 4px;
        width: 25px;
        height: 25px;
      }

      &:hover {
        background-color: rgba($primary, 0.3);
      }
    }
  }

  .langSwitch {
    img {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      &.selected {
        outline: 4px solid $secondary;
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    padding-inline: 10px;
    padding-bottom: 25px;
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(-92.5px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scaleX(1);
  }
}
