.footer {
  background-color: rgba(#00add8, 0.35);

  &-logo {
    img {
      width: 260px;
    }
  }

  &-wrapper {
    max-width: 700px;
  }

  .social-icon {
    display: flex;
    width: 38px;
    height: 38px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #eaecf1;
    transition: background-color 200ms cubic-bezier(0.165, 0.84, 0.44, 1),
      color 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
    color: #5c6c86;

    &:hover {
      background-color: rgba(92, 108, 134, 0.2);
      color: #fff;
    }
  }

  .followUs {
    font-weight: bold;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
  .contactInfo {
    font-size: 13px;
    > div {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 8px;
      font-size: 18px;
    }

    a {
      color: var(--bs-body-color);
    }
  }
  .copyRight {
    font-size: 14px;
  }
}
